import React, { useState, useEffect } from "react";
import axios from "axios";
import { MaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
// import { Box, IconButton, Button, Modal } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
// import EditModal from "./EditModal";
// import CreateModal from "./CreateModal";
import css from "./Jobs.module.css";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Modal,
  IconButton,
} from "@mui/material";
import EditForm from "./EditJob";
import CreateJobForm from "./CreateJobForm";
const Jobs = () => {
  const [data, setData] = useState([]);
  const [updatedData, setupdatedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [vacancyToDelete, setVacancyToDelete] = useState(null);

  const [vacancies, setVacancies] = useState([]);
  const [editingVacancy, setEditingVacancy] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [updatedAt, setUpdatedAt] = useState("");
  const ITEMS_PER_PAGE = 10;
  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_BASE_URL;
  const fetchData = async () => {
    const token = sessionStorage.getItem("token");

    try {
      const response = await axios.get(
        `${baseURL}/vacancy/all?page=${currentPage}&limit=10`
      );
      const totalData = response.data.totalData;
      setTotalPages(Math.ceil(totalData / 10));
      setTotalData(totalData);
      // Assuming 10 items per page
      const vacancies = response.data.vacancies;
      setData(vacancies);
      setLoading(false);
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        navigate("/login"); // Redirect to the login page for unauthorized or forbidden errors
      } else {
        console.error("Error fetching data:", error);
        setLoading(false);
        throw error;
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, totalData, updatedData]);

  const handleEditClick = (vacancy) => {
    setEditingVacancy(vacancy); // Set the editing vacancy
  };
  const handleDeleteClick = (id, title) => {
    setVacancyToDelete({ id, title });
    setShowModal(true);
  };

  const confirmDelete = async () => {
    const token = sessionStorage.getItem("token");
    try {
      await axios.delete(`${baseURL}/vacancy/${vacancyToDelete.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedData = data.filter(
        (vacancy) => vacancy._id !== vacancyToDelete.id
      );

      // Check if the current page still has data after deletion
      if (updatedData.length === 0 && currentPage > 1) {
        // If current page becomes empty, go back to the previous page
        setCurrentPage((prevPage) => prevPage - 1);
      } else {
        setCurrentPage(currentPage);
        // Otherwise, update the data for the current page
        setData(updatedData);
        setShowModal(false);
      }
      alert("Job Deleted successfully!");
    } catch (error) {
      console.error("Error deleting data: ", error);
    }
  };

  const handleSave = (editedVacancy) => {
    const updatedVacancies = vacancies.map((v) =>
      v._id === editedVacancy.id ? editedVacancy : v
    );

    setupdatedData(updatedVacancies);
    setData(updatedVacancies);
    setEditingVacancy(null);
  };
  const handleCancel = () => {
    setEditingVacancy(null); // Clear the editing vacancy if canceled
  };

  const handleCreateJobClick = () => {
    setShowCreateForm(true);
  };

  const handleCancelCreateJob = () => {
    setShowCreateForm(false);
  };

  const handlCreateJob = async () => {
    setTotalData(totalData + 1);
    setShowCreateForm(false);
  };

  const columns = [
    { accessorKey: "title", header: "Title" },
    { accessorKey: "yearsOfExperience", header: "Years of Experience" },
    { accessorKey: "gpa", header: "Required GPA" },
    { accessorKey: "educationalQualification", header: "Educational Status" },
    { accessorKey: "educationalBackground", header: "Educational Background" },
    { accessorKey: "numberOfVacancy", header: "Number of Vacancy" },
  ];

  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages);
  };

  return (
    <div className="table-container">
      <ThemeProvider theme={theme}>
        {loading ? (
          <div className={css.loader}>
            <div className={css.outer}></div>
            <div className={css.middle}></div>
            <div className={css.inner}></div>
          </div>
        ) : (
          <>
            {" "}
            {showSuccessAlert && (
              <div className={styles.successAlert}>Edited Successfully!</div>
            )}
            <MaterialReactTable
              columns={columns}
              data={data}
              positionActionsColumn={"last"}
              enablePagination={false}
              enableRowActions={true}
              enableTopToolbar={true}
              renderTopToolbarCustomActions={({ row }) => (
                <Button
                  startIcon={<WorkIcon />}
                  variant="contained"
                  onClick={handleCreateJobClick}
                >
                  Create New Job
                </Button>
              )}
              renderRowActions={({ row }) => (
                <Box>
                  <IconButton onClick={() => handleEditClick(row.original)}>
                    <EditIcon className={css.editbutton} />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      handleDeleteClick(row.original._id, row.original.title)
                    }
                  >
                    <DeleteIcon className={css.deletebutton} />
                  </IconButton>
                </Box>
              )}
            />
          </>
        )}
      </ThemeProvider>
      {totalData > 0 && (
        <div className={css.pagination}>
          <button onClick={goToFirstPage} disabled={currentPage === 1}>
            {"<<"}{" "}
          </button>
          <button onClick={prevPage} disabled={currentPage === 1}>
            {"<"}
          </button>
          <span>
            {currentPage} / {totalPages}
          </span>
          <button onClick={nextPage} disabled={currentPage === totalPages}>
            {">"}
          </button>
          <button onClick={goToLastPage} disabled={currentPage === totalPages}>
            {">>"}
          </button>
        </div>
      )}
      {editingVacancy && (
        <EditForm
          vacancy={editingVacancy}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
      {showCreateForm && (
        <CreateJobForm
          onCancel={handleCancelCreateJob}
          onCreate={handlCreateJob}
        />
      )}
      {showModal && (
        <div className={css.modaloverlay}>
          <div className={css.modal}>
            <div>
              <h2>Confirm Deletion</h2>
              <p>{`Are you sure you want to delete "${vacancyToDelete?.title}"?`}</p>
              <div className={css.modalbutton}>
                <button className={css.delete} onClick={confirmDelete}>
                  Delete
                </button>
                <button
                  className={css.cancel}
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Jobs;
