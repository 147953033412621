import css from "./Statistics.module.css";
import { BsArrowUpShort } from "react-icons/bs";

import StatisticsChart from "../StatisticsChart/StatisticsChart";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Statistics = () => {
  const [totalApplicantData, setTotalApplicantData] = useState(0); // State to store total data count
  const [totalJobData, setTotaJoblData] = useState(0); // State to store total data count
  const [totalApprovedata, settotalApprovedata] = useState(0);
  const navigate = useNavigate();
   const baseURL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const fetchData = async () => {
      const token = sessionStorage.getItem("token");

      try {
        const [applicantResponse, jobResponse, approvedResponse] =
          await Promise.all([
            axios.get(
            `${baseURL}/application/?page=1&limit=5`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            ),
            axios.get(
              `${baseURL}/vacancy/all?page=1&limit=10`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            ),
            axios.get(
              `${baseURL}/application/stats`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            ),
          ]);

        setTotalApplicantData(applicantResponse.data.totalData);
        setTotaJoblData(jobResponse.data.totalData);
        settotalApprovedata(approvedResponse.data.stats.totalApproved);
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          navigate("/login"); // Redirect to the login page for unauthorized or forbidden errors
        } else {
          console.error("Error fetching data:", error);
          throw error;
        }
      }
    };

    fetchData();
  }, []);
  return (
    <div className={`${css.container} theme-container`}>
      <span className={css.title}>Overview Statistics</span>

      <div className={`${css.cards} grey-container`}>
        <div>
          <div className={css.card}>
            <span className={css.summary}>Summary</span>
          </div>
        </div>

        <div className={css.card}>
          <span>Job Posted</span>
          <span> {groupNumber(totalJobData)}</span>
        </div>

        <div className={css.card}>
          <span>Total Applicants</span>
          <span>{groupNumber(totalApplicantData)}</span>
        </div>

        <div className={css.card}>
          <span>Hired </span>
          <span>{groupNumber(totalApprovedata)}</span>
        </div>
      </div>

      <StatisticsChart />
    </div>
  );
};

export const groupNumber = (number) => {
  return parseFloat(number.toFixed(2)).toLocaleString("en", {
    useGrouping: true,
  });
};
export default Statistics;
