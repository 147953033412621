import css from "./Sidebar.module.css";
import { MdSpaceDashboard } from "react-icons/md";
import { AiFillCalendar, AiOutlineTable } from "react-icons/ai";
import { FaTasks } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import React, { useState } from "react";

const Sidebar = () => {
  const navigateTo = useNavigate();
  // Assuming you have a logout function in AuthContext
  const [token, setToken] = useState(sessionStorage.getItem("token") || null);

  const handleLogout = () => {
   navigateTo("login");
    setToken(null);
    sessionStorage.removeItem("token");
   
  };

  return (
    <div className={css.container}>
      <img src="./assets/images/logo.png" alt="logo" className={css.logo} />

      <div className={css.menu}>
        <NavLink to="dashboard" className={css.item} title={"Dashboard"}>
          <MdSpaceDashboard size={30} />
        </NavLink>
        <NavLink to="jobs" className={css.item} title="Jobs">
          <AiOutlineTable size={30} />
        </NavLink>
        <NavLink to="applicants" className={css.item} title="Applicants">
          <HiOutlineUserGroup size={30} />
        </NavLink>

        <NavLink to="calendar" className={css.item} title="Calendar">
          <AiFillCalendar size={30} />
        </NavLink>

        {/* <NavLink to="board" className={css.item} title="Daily Task Board">
            <FaTasks size={30} />
          </NavLink> */}

        <button onClick={handleLogout} className={css.specialItem}>
          <FiLogOut color={"white"} size={30} />
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
