import React, { Component } from "react";
import {
  HashRouter as Router,
  Route,
  NavLink,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { useState, createContext, useEffect } from "react";
 
import "./Login.css";

export const AuthContext = createContext();

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(null);

  const navigateTo = useNavigate();

  const [errors, setErrors] = useState({
    username: "",
    password: "",
    generic: "", // For API response error
  });
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      navigateTo("/");
    }
  }, []); // Log token value whenever it changes
  // Log token value whenever it changes

  const clearErrors = () => {
    setErrors({
      username: "",
      password: "",
      generic: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!username.trim()) {
      newErrors.username = "Username is required.";
    }
    if (!password.trim()) {
      newErrors.password = "Password is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      const response = await fetch(
        `${baseURL}/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        const accessToken = data.accessToken; // Assuming token key is accessToken

        sessionStorage.setItem("token", accessToken);
        setToken(accessToken);
        navigateTo("/");
        console.log(token);
        clearErrors();
      } else {
        // Handle login error
        const errorData = await response.json(); // Parse error response
        setErrors({ ...newErrors, generic: errorData.message });
        console.error("Login failed");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrors({
        ...newErrors,
        generic: "An error occurred. Please try again.", // Set generic error message
      });
    }
  };

  return (
    <div className="App">
      <div
        className="appAside"
        style={{
          backgroundImage: `url(assets/images/logo3.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Your content here */}
      </div>

      <div className="appForm">
        <div className="formTitle">
          <NavLink
            to="/login"
            activeClassName="formTitleLink-active"
            className="formTitleLink"
          >
            Sign In
          </NavLink>{" "}
        </div>
        <div className="formCenter">
          <form className="formFields" onSubmit={handleSubmit}>
            <div className="formField">
              <label className="formFieldLabel" htmlFor="email">
                Username
              </label>
              <input
                type="text"
                id="username"
                className="formFieldInput"
                placeholder="Enter your username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              {errors.username && (
                <p className="errorTextu">{errors.username}</p>
              )}
            </div>

            <div className="formField">
              <label className="formFieldLabel" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="formFieldInput"
                placeholder="Enter your password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {errors.password && (
                <p className="errorTextu">{errors.password}</p>
              )}
            </div>

            <div className="formFbuttonield">
              <button type="submit" className="formFieldButton">
                Sign In
              </button>{" "}
            </div>
            {errors.generic && (
              <div className="formField">
                <p className="errorText">{errors.generic}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
