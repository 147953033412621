import React, { useState, useEffect } from "react";


import css from "./RecentApplicants.module.css";
import axios from "axios";
import Statistics from "../Statistics/Statistics"; // Import Axios for making HTTP requests
import { useNavigate } from "react-router-dom";
const Orders = () => {
  const [ordersData, setOrdersData] = useState([]); // State to store fetched orders data
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalData, setTotalData] = useState(0); // State to store total data count
 const navigate = useNavigate();
 const baseURL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.get(
          `${baseURL}/application/?page=${currentPage}&limit=5`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setOrdersData(response.data.applications); // Set fetched data to state
        setTotalPages(Math.ceil(response.data.totalData / 5)); // Calculate total pages based on total data count
        setTotalData(response.data.totalData); // Set total data count
      } catch (error)  {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      navigate('/login'); // Redirect to the login page for unauthorized or forbidden errors
    } else {
      console.error("Error fetching data:", error);
      throw error;
    }
      }
    };

    fetchData(); // Call fetchData function when component mounts or currentPage changes
  }, [currentPage]);
  // Empty dependency array ensures useEffect runs only once when component mounts
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  return (
    <div className={`${css.container} theme-container`}>
      <div className={css.head}>
        <img src="./assets/images/logo.png" alt="logo" />
        <span>Recent Applicants</span>
      </div>

      <div className={`grey-container ${css.stat}`}>
        <span>Amount</span>
        <span>{groupNumber(totalData)}</span>{" "}
        {/* Display number of fetched orders */}
      </div>

      <div className={css.orders}>
        {ordersData.map((order, index) => (
          <div key={index} className={css.order}>
            <div>
              <span>Name: {order.fullName}</span>
              <span>Phone: {order.phone}</span>
            </div>
            <div>
              <span>Job Title: {order.vacancy.title} </span> 
              <span>Experience: {order.yearsOfExperience} Years</span>
            </div>
          </div>
        ))}
      </div>
      <div className={css.pagination}>
        <button onClick={prevPage} disabled={currentPage === 1}>
          {"<"} {/* Prev arrow icon */}
        </button>
        <span>
          {currentPage} / {totalPages}
        </span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          {">"} {/* Next arrow icon */}
        </button>
      </div>
    </div>
  );
};

export const groupNumber = (number) => {
  return parseFloat(number.toFixed(2)).toLocaleString("en", {
    useGrouping: true,
  });
};
export default Orders;
