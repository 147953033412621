import React, { useState, useEffect } from "react";
import Orders from "../../components/RecentApplicant/RecentApplicants";
import Statistics from "../../components/Statistics/Statistics";
// Import the fetchCardsData and groupNumber functions
import css from "./Dashboard.module.css";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {

  const [cardsData, setCardsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


    const navigate = useNavigate(); 
    
  useEffect(() => {
    fetchCardsData()
      .then((data) => {
        if (data) {
          setCardsData(data);
        }
      }).catch ((error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      navigate('/login'); // Redirect to the login page for unauthorized or forbidden errors
    } else {
      console.error("Error fetching data:", error);
      throw error;
    }
      })
      .finally(() => {
        setIsLoading(false); // Update loading status when fetch is complete
      });
  }, []); // Run only once on component mount

  return (
    <div className={css.container}>
      {/* left side */}

      <div className={css.dashboard}>
        <div className={`${css.dashboardHead} theme-container`}>
          <div className={css.head}>
            <span>Dashboard</span>
          </div>
          {isLoading ? ( // Render loading indicator if data is still loading
            <div class={css.loader}></div>
          ) : (
            <div className={css.cards}>
              {cardsData.map((card, index) => (
                <div className={css.card} key={index}>
                  <div className={css.cardHead}>
                    <span>{card.title}</span>
                    <span
                      style={
                        card.title === "Total Rejected"
                          ? { color: "red" }
                          : card.title === "Total Pending"
                          ? { color: "yellow" }
                          : card.title === "Total Scheduled"
                          ? { color: "grey" }
                          : {}
                      }
                    >
                      {card.change}
                    </span>
                  </div>
                  <div className={css.cardAmount}>
                    <span></span>
                    <span>{card.amount}</span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <Statistics />
      </div>

      <Orders />
    </div>
  );
};
const baseURL = process.env.REACT_APP_BASE_URL;
async function fetchCardsData() {
  const apiUrl = `${baseURL}/application/stats`;
  const token = sessionStorage.getItem("token");
  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();

    if (data && data.success) {
      const stats = data.stats;

      const cardsData = [
        {
          title: "Total Applications",
          //change: stats.approvedPercentage, // You can set this to whatever appropriate value
          amount: stats.totalApplicaitons,
        },
        {
          title: "Total Rejected",
          change: groupNumber(stats.rejectedPercentage) + " %",
          amount: stats.totalRejected,
        },
        {
          title: "Total Approved",
          change: groupNumber(stats.approvedPercentage) + " %",
          amount: stats.totalApproved,
        },
        {
          title: "Total Scheduled",
          change: groupNumber(stats.schedulePercentage) + " %",
          amount: stats.totalScheduled,
        },
        {
          title: "Total Pending",
          change: groupNumber(stats.pendingPercentage) + " %",
          amount: stats.totalPending,
        },
      ];

      return cardsData;
    } else {
      throw new Error("Invalid response format");
    }
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      navigate("/login"); // Redirect to the login page for unauthorized or forbidden errors
    } else {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
  // } catch (error) {

  //   console.error("Error fetching data:", error);
  //   return null;
  // }
}

export const groupNumber = (number) => {
  return parseFloat(number.toFixed(2)).toLocaleString("en", {
    useGrouping: true,
  });
};

export default Dashboard;
