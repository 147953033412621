import React, { useState, useEffect } from "react";
import axios from "axios";
import { MaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import css from "./Applicants.module.css";
import { Box, Typography, IconButton, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { useNavigate } from "react-router-dom";

import Modal from "react-modal";

const Applicants = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalData, setTotalData] = useState(0);

  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [approvedModalOpen, setApprovedModalOpen] = useState(false);
  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      const token = sessionStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      try {
        const response = await axios.get(
          `${baseURL}/application/?page=${currentPage}&limit=10`,
          { headers }
        );
        const applications = response.data.applications;

        const formattedData = applications.map((application) => ({
          fullName: application.fullName,
          address: application.address,
          yearsOfExperience: application.yearsOfExperience + " Years",
          email: application.email,
          phone: application.phone,
          educationalQualification: application.educationalQualification,
          cv: application.cv,
          gpa: application.gpa,
          university: application.university,
          status: application.status,
          id: application._id,
          title: application.vacancy.title,
          vacancyyearsOfExperience: application.vacancy.yearsOfExperience,
        }));

        setTotalPages(Math.ceil(response.data.totalData / 10));
        setTotalData(response.data.totalData);
        setData(formattedData);
        setLoading(false);
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          navigate("/login"); // Redirect to the login page for unauthorized or forbidden errors
        } else {
          setLoading(false);
          console.error("Error fetching data:", error);
          throw error;
        }
      }
    };

    fetchData();
  }, [currentPage]);

  const handleApprove = async (id) => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.put(
        `${baseURL}/application/approve/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Update the status locally if the API call is successful
      const updatedData = data.map((applicant) =>
        applicant.id === id ? { ...applicant, status: "approved" } : applicant
      );
      setData(updatedData);
      setSelectedApplicantId(id);
      setApprovedModalOpen(true);
    } catch (error) {
      console.error("Error approving application:", error);
    }
  };

  const handleReject = async (id, rejectionReason) => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.put(
        `${baseURL}/application/reject/${id}`,
        { rejectionReason }, // Include the rejection reason in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Update the status locally if the API call is successful
      const updatedData = data.map((applicant) =>
        applicant.id === id ? { ...applicant, status: "rejected" } : applicant
      );
      setData(updatedData);
      alert("Applicant rejected successfully!");
    } catch (error) {
      console.error("Error rejecting application:", error);
    }
  };

  const handleScheduleInterview = async (id, interviewDate, interviewTime) => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.put(
        `${baseURL}/application/schedule-interview/${id}`,
        { interviewDate, interviewTime }, // Include the interview date in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Update the status locally if the API call is successful
      const updatedData = data.map((applicant) =>
        applicant.id === id ? { ...applicant, status: "scheduled" } : applicant
      );
      setData(updatedData);
      alert("Interview scheduled successfully!");
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        navigate("/login"); // Redirect to the login page for unauthorized or forbidden errors
      } else {
        console.error("Error fetching data:", error);
        throw error;
      }
    }
  };

  const handleRejectClick = (id) => {
    setSelectedApplicantId(id);
    setRejectModalOpen(true);
  };

  // Example UI component to capture interview date
  const handleScheduleInterviewClick = (id) => {
    setSelectedApplicantId(id);
    setScheduleModalOpen(true);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const columns = [
    { accessorKey: "fullName", header: "Full Name" },
    { accessorKey: "title", header: "Job Applied" },
    { accessorKey: "yearsOfExperience", header: "Years of Experience" },
    { accessorKey: "gpa", header: "GPA" },
    { accessorKey: "email", header: "Email" },
    { accessorKey: "phone", header: "Phone" },
  ];

  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages);
  };

  const getStatusClassName = (status) => {
    switch (status) {
      case "pending":
        return css.pending;
      case "approved":
        return css.approved;
      case "rejected":
        return css.rejected;
      case "scheduled":
        return css.scheduled;
      default:
        return ""; // default class if status is not recognized
    }
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  // const Example = () => {
  //   const handleExportRows = (rows= data => {
  //     const rowData = rows.map((row) => row.original);
  //     const csv = generateCsv(csvConfig)(rowData);
  //     donload(csvConfig)(csv);
  //   };

  //   const handleExportData = () => {
  //     const csv = generateCsv(csvConfig)(data);
  //     download(csvConfig)(csv);
  //   };}
  return (
    <div className="table-container">
      <ThemeProvider theme={theme}>
        {loading ? (
          <div className={css.loader}>
            <div className={css.outer}></div>
            <div className={css.middle}></div>
            <div className={css.inner}></div>
          </div>
        ) : (
          <>
            <ApprovedModal
              isOpen={approvedModalOpen}
              onRequestClose={() => setApprovedModalOpen(false)}
            />
            <InterviewModal
              isOpen={scheduleModalOpen}
              onRequestClose={() => setScheduleModalOpen(false)}
              handleScheduleInterview={handleScheduleInterview}
              id={selectedApplicantId}
              className={css.modaldiv}
            />
            <RejectModal
              isOpen={rejectModalOpen}
              closeModal={() => setRejectModalOpen(false)}
              handleReject={handleReject}
              id={selectedApplicantId}
            />

            <MaterialReactTable
              columns={columns}
              data={data}
              enableEditing={true}
              positionActionsColumn={"last"}
              enablePagination={false}
              enableRowActions={true}
              enableTopToolbar={true}
              getRowId={(row) => row.id}
              // muiToolbarAlertBannerProps={
              //   {isLoadingUsersError}
              //     ? {
              //         color: "error",
              //         children: "Error loading data",
              //       }
              //     : undefined
              // }
              muiTableContainerProps={{
                sx: {
                  minHeight: "500px",
                },
              }}
              renderRowActions={({ row }) => (
                <Box display="flex">
                  <IconButton
                    onClick={() =>
                      handleScheduleInterviewClick(row.original.id)
                    }
                    style={{ color: "Yellow" }}
                  >
                    <CalendarMonthIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleApprove(row.original.id)}
                    style={{ color: "green" }}
                  >
                    <DoneIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRejectClick(row.original.id)}
                    style={{ color: "red" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "16px",
                    padding: "8px",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                  >
                    Export All Data
                  </Button>
                </Box>
              )}
              renderDetailPanel={({ row }) =>
                row.original.id ? (
                  <Box
                    sx={{
                      display: "flex",
                      margin: "1",
                      gap: "5rem",
                      width: "100%",
                      "& > *": {
                        margin: "2rem 0", // Adjust the margin as needed
                      },
                      "& > *:not(:last-child)": {
                        marginBottom: "2rem", // Adjust the gap between elements
                      },
                    }}
                  >
                    {/* <Typography>Job Title: {row.original.title}</Typography> */}
                    <Typography>
                      University:{" "}
                      <span
                        className={getStatusClassName(row.original.university)}
                      >
                        {row.original.university}
                      </span>
                    </Typography>
                    <Typography>
                      Educational Status :{" "}
                      <span
                        className={getStatusClassName(
                          row.original.educationalQualification
                        )}
                      >
                        {row.original.educationalQualification}
                      </span>
                    </Typography>
                    <Typography>
                      Required Experience :
                      {row.original.vacancyyearsOfExperience} Years
                    </Typography>

                    <Typography>
                      Status:{" "}
                      <span className={getStatusClassName(row.original.status)}>
                        {row.original.status}
                      </span>
                    </Typography>
                    <Typography>
                      CV:
                      <a
                        className={css.downloadcvbtn}
                        href={row.original.cv}
                        target="_blank"
                        // rel="noopener noreferrer"
                      >
                        Download CV
                      </a>
                    </Typography>
                  </Box>
                ) : null
              }
            />
          </>
        )}
      </ThemeProvider>
      {totalData > 0 && (
        <div className={css.pagination}>
          <button onClick={goToFirstPage} disabled={currentPage === 1}>
            {"<<"}{" "}
          </button>
          <button onClick={prevPage} disabled={currentPage === 1}>
            {"<"}
          </button>
          <span>
            {currentPage} / {totalPages}
          </span>
          <button onClick={nextPage} disabled={currentPage === totalPages}>
            {">"}
          </button>
          <button onClick={goToLastPage} disabled={currentPage === totalPages}>
            {">>"}
          </button>
        </div>
      )}
    </div>
  );
};

export default Applicants;

const InterviewModal = ({
  isOpen,
  onRequestClose,
  handleScheduleInterview,
  id,
}) => {
  const [interviewDate, setInterviewDate] = useState(new Date());
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");

  const handleSchedule = () => {
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;

    const interviewTime = `${formattedHour}:${formattedMinute}`;
    // const interviewTime = `${hour}:${minute}`;
    handleScheduleInterview(
      id,
      interviewDate.toISOString().slice(0, 10),
      interviewTime
    );
    onRequestClose();
  };

  return (
    <Modal
      overlayClassName={css.modaloverlay}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={css.modal}
    >
      <h2>Schedule Interview</h2>
      <div>
        <label>Interview Date:</label>
        <DatePicker
          selected={interviewDate}
          onChange={(date) => setInterviewDate(date)}
        />
      </div>
      <div>
        <label>Interview Time:</label>
        <div className={css.timeInputs}>
          <div>
            <select value={hour} onChange={(e) => setHour(e.target.value)}>
              <option value="">Hour</option>
              {[...Array(24).keys()].map((hour) => (
                <option key={hour} value={hour.toString()}>
                  {hour < 10 ? `0${hour}` : hour}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select value={minute} onChange={(e) => setMinute(e.target.value)}>
              <option value="">Minute</option>
              {[...Array(60).keys()].map((minute) => (
                <option key={minute} value={minute.toString()}>
                  {minute < 10 ? `0${minute}` : minute}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className={css.modalbutton}>
        <button onClick={handleSchedule} className={css.submit}>
          Schedule
        </button>
        <button onClick={onRequestClose} className={css.cancel}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};
function RejectModal({ isOpen, closeModal, handleReject, id }) {
  const [rejectionReason, setRejectionReason] = useState("");

  const handleRejectClick = () => {
    // Implement rejection logic
    handleReject(id, rejectionReason);
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={css.modal}
      overlayClassName={css.modaloverlay}
    >
      <h2>Enter Rejection Reason</h2>
      <input
        type="text"
        value={rejectionReason}
        onChange={(e) => setRejectionReason(e.target.value)}
        placeholder="Enter rejection reason"
      />
      <div className={css.modalbutton}>
        <button onClick={handleRejectClick} className={css.submit}>
          Submit
        </button>
        <button onClick={closeModal} className={css.cancel}>
          Cancel
        </button>
      </div>
    </Modal>
  );
}
const ApprovedModal = ({ isOpen, onRequestClose }) => {
  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => {
        onRequestClose(); // Close the modal after 2 seconds
      }, 2000);

      return () => clearTimeout(timeout); // Clear the timeout when the component unmounts or modal closes
    }
  }, [isOpen, onRequestClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={css.modal}
      overlayClassName={css.modaloverlay}
    >
      <p className={css.ap}>Approved!!!</p>
      <p className={css.app}>Your application has been approved.</p>
    </Modal>
  );
};
