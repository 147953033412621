import React, { useState } from "react";
import axios from "axios";
import styles from "./EditForm.module.css"; // Import CSS module
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditForm = ({ vacancy, onSave, onCancel }) => {
  const [editedVacancy, setEditedVacancy] = useState({ ...vacancy });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue =
      name === "numberOfVacancy" || name === "yearsOfExperience"
        ? parseInt(value)
        : value;
    setEditedVacancy((prevVacancy) => ({
      ...prevVacancy,
      [name]: parsedValue,
    }));
  };
  const baseURL = process.env.REACT_APP_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});
    try {
      // Perform validation
      const validationErrors = {};

      if (!editedVacancy.title) {
        validationErrors.title = "Title is required";
      }

      if (!editedVacancy.description) {
        validationErrors.description = "Description is required";
      }

      if (!editedVacancy.experienceLevel) {
        validationErrors.experienceLevel = "Experience level is required";
      }

      if (!editedVacancy.yearsOfExperience) {
        validationErrors.yearsOfExperience = "Years of experience is required";
      }

      if (!editedVacancy.numberOfVacancy) {
        validationErrors.numberOfVacancy = "Number of vacancies is required";
      } else if (
        isNaN(editedVacancy.numberOfVacancy) ||
        editedVacancy.numberOfVacancy <= 0
      ) {
        validationErrors.numberOfVacancy =
          "Number of vacancies must be a positive number";
      }

      if (!editedVacancy.educationalBackground) {
        validationErrors.educationalBackground =
          "Educational background is required";
      }
      if (!editedVacancy.educationalQualification) {
        validationErrors.educationalQualification =
          "Educational Qualification is required";
      }

      if (!editedVacancy.gender) {
        validationErrors.gender = "Gender is required";
      }
      if (!editedVacancy.gpa) {
        validationErrors.gpa = "GPA is required";
      }
      if (!editedVacancy.salary) {
        validationErrors.salary = "Salary is required";
      }

      if (!editedVacancy.jobType) {
        validationErrors.jobType = "Job type is required";
      }

      if (!editedVacancy.expiryDate) {
        validationErrors.expiryDate = "Expiry date is required";
      }

      if (Object.keys(validationErrors).length > 0) {
        setLoading(false);
        setErrors(validationErrors);
        return; // Stop form submission if there are validation errors
      }

      const token = sessionStorage.getItem("token");

      // Remove unwanted properties from the editedVacancy object
      const { _id, createdAt, updatedAt, isAdjusted, __v, ...editedData } =
        editedVacancy;

      await axios.put(
        `${baseURL}/vacancy/${editedVacancy._id}`,
        editedData, // Send only the edited data without unwanted properties
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onSave(editedVacancy);

      setEditedVacancy(editedVacancy);
      alert("Job Edited successfully!");
      setShowSuccessAlert(true);
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        navigate("/login"); // Redirect to the login page for unauthorized or forbidden errors
      } else {
        console.error("Error fetching data:", error);
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date) => {
    setEditedVacancy({ ...editedVacancy, expiryDate: date });
  };

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContainer}>
        <form onSubmit={handleSubmit}>
          <h3>Edit Vacancy</h3>
          <label>
            Job Title:
            <input
              type="text"
              name="title"
              value={editedVacancy.title}
              onChange={handleChange}
              disabled={loading}
            />
            {errors.title && (
              <span className={styles.error}>{errors.title}</span>
            )}
          </label>
          <label>
            Description:
            <textarea
              name="description"
              value={editedVacancy.description}
              onChange={handleChange}
              disabled={loading}
            />
            {errors.description && (
              <span className={styles.error}>{errors.description}</span>
            )}
          </label>

          <label>
            Experience Level:
            <select
              name="experienceLevel"
              value={editedVacancy.experienceLevel}
              onChange={handleChange}
              disabled={loading}
            >
              <option value="">Select Experience Level</option>
              <option value="senior">Senior</option>
              <option value="intermediate">Intermediate</option>
              <option value="junior">Junior</option>
            </select>
            {errors.experienceLevel && (
              <span className={styles.error}>{errors.experienceLevel}</span>
            )}
          </label>
          <label>
            Years of Experience:
            <input
              type="number"
              name="yearsOfExperience"
              value={editedVacancy.yearsOfExperience}
              onChange={handleChange}
              disabled={loading}
            />
            {errors.yearsOfExperience && (
              <span className={styles.error}>{errors.yearsOfExperience}</span>
            )}
          </label>
          <label>
            Personnel Required:
            <input
              type="number"
              name="numberOfVacancy"
              value={editedVacancy.numberOfVacancy}
              onChange={handleChange}
              disabled={loading}
            />
            {errors.numberOfVacancy && (
              <span className={styles.error}>{errors.numberOfVacancy}</span>
            )}
          </label>
          <label>
            Education Background:
            <input
              type="text"
              name="educationalBackground"
              value={editedVacancy.educationalBackground}
              onChange={handleChange}
              disabled={loading}
            />
            {errors.educationalBackground && (
              <span className={styles.error}>
                {errors.educationalBackground}
              </span>
            )}
          </label>
          <label>
            Educational Status
            <select
              name="educationalQualification"
              value={editedVacancy.educationalQualification}
              onChange={handleChange}
              disabled={loading}
            >
              <option value="">Select Status</option>
              <option value="BSC">Bsc</option>
              <option value="MSC">Msc</option>
              <option value="PHD">Phd</option>
              <option value="ANY">Other</option>
            </select>
            {errors.educationalQualification && (
              <span className={styles.error}>
                {errors.educationalQualification}
              </span>
            )}
          </label>
          <label>
            GPA:
            <input
              type="number"
              name="gpa"
              value={editedVacancy.gpa}
              onChange={handleChange}
              disabled={loading}
            />
            {errors.gpa && <span className={styles.error}>{errors.gpa}</span>}
          </label>
          <label>
            Gender
            <select
              name="gender"
              value={editedVacancy.gender}
              onChange={handleChange}
              disabled={loading}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="part_time">Both</option>
            </select>
            {errors.gender && (
              <span className={styles.error}>{errors.gender}</span>
            )}
          </label>
          <label>
            Salary:
            <input
              type="text"
              name="salary"
              value={editedVacancy.salary}
              onChange={handleChange}
              disabled={loading}
            />
            {errors.salary && (
              <span className={styles.error}>{errors.salary}</span>
            )}
          </label>
          <label>
            Job Type:
            <select
              name="jobType"
              value={editedVacancy.jobType}
              onChange={handleChange}
              disabled={loading}
            >
              <option value="">Select Job Type</option>
              <option value="full_time">Full Time</option>
              <option value="part_time">Part Time</option>
              <option value="contractual">Contract</option>
              <option value="internship">Internship</option>
            </select>
            {errors.jobType && (
              <span className={styles.error}>{errors.jobType}</span>
            )}
          </label>
          <label>
            Expiry Date:
            <DatePicker
              className={styles.date}
              selected={editedVacancy.expiryDate}
              onChange={(date) => handleDateChange(date)}
              disabled={loading}
            />
            {errors.expiryDate && (
              <span className={styles.errorex}>{errors.expiryDate}</span>
            )}
          </label>

          <div className={styles.buttons}>
            <button type="submit" className={styles.save} disabled={loading}>
              {loading ? "Saving" : "Save"}
            </button>
            <button
              type="button"
              className={styles.cancel}
              onClick={onCancel}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </form>
        {showSuccessAlert && (
          <div className={styles.successAlert}>Edited Successfully!</div>
        )}
      </div>
    </div>
  );
};

export default EditForm;
