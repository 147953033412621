import React, { useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import styles from "./CreateJobForm.module.css"; // Import the module CSS file
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  HashRouter as Router,
  Route,
  NavLink,
  useNavigate,
} from "react-router-dom";

const CreateJobForm = ({ onCreate, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [jobData, setJobData] = useState({
    title: "",
    description: "",
    yearsOfExperience: "",
    numberOfVacancy: "",
    gpa: "",
    educationalQualification: "",
    educationalBackground: "",
    expiryDate: null,
  });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue =
      name === "numberOfVacancy" || name === "yearsOfExperience"
        ? parseInt(value)
        : value;
    setJobData((prevData) => ({
      ...prevData,
      [name]: parsedValue,
    }));
  };
  const baseURL = process.env.REACT_APP_BASE_URL;

  const handleDateChange = (date) => {
    setJobData({ ...jobData, expiryDate: date });
  };

  const handleSubmit = async (e) => {
    setErrors({});
    e.preventDefault();

    const validationErrors = {};

    // Validate required fields
    if (!jobData.title) {
      validationErrors.title = "Title is required";
    }
    if (!jobData.description) {
      validationErrors.description = "Description is required";
    }
    if (!jobData.experienceLevel) {
      validationErrors.experienceLevel = "Experience level is required";
    }
    if (!jobData.yearsOfExperience) {
      validationErrors.yearsOfExperience = "Years of experience is required";
    }
    if (!jobData.numberOfVacancy) {
      validationErrors.numberOfVacancy = "Number of vacancies is required";
    }
    if (!jobData.gpa) {
      validationErrors.gpa = "GPA is required";
    }
   
    if (!jobData.educationalBackground) {
      validationErrors.educationalBackground =
        "Educational background is required";
    }
    if (!jobData.educationalQualification) {
      validationErrors.educationalQualification =
        "Educational Qualification is required";
    }
    if (!jobData.gender) {
      validationErrors.gender = "Gender is required";
    }
    if (!jobData.salary) {
      validationErrors.salary = "Salary is required";
    }
    if (!jobData.jobType) {
      validationErrors.jobType = "Job type is required";
    }
    if (!jobData.expiryDate) {
      validationErrors.expiryDate = "Expiry date is required";
    }
    if (Object.keys(validationErrors).length !== 0) {
      // Update state with validation errors
      setErrors(validationErrors);
      // Return early if there are validation errors
      return;
    }
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(`${baseURL}/vacancy`, jobData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      // Trigger the onCreate callback to update the job table
      onCreate(response.data);

      // Close the form
      setShowSuccessAlert(true);
      setTimeout(() => {
        onCancel();
        setShowSuccessAlert(false);
      }, 8000); // Close after 8 seconds
    } catch (error) {
      setLoading(false);
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        navigate("/login"); // Redirect to the login page for unauthorized or forbidden errors
      } else {
        console.error("Error fetching data:", error);
        throw error;
      }
    }
  };

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContainer}>
        <form onSubmit={handleSubmit}>
          <h3>Create Vacancy</h3>
          <label>
            Job Title:
            <input
              type="text"
              name="title"
              value={jobData.title}
              onChange={handleChange}
              disabled={loading}
            />
            {errors.title && (
              <span className={styles.error}>{errors.title}</span>
            )}
          </label>
          <label>
            Description:
            <textarea
              name="description"
              value={jobData.description}
              onChange={handleChange}
              disabled={loading}
            />
            {errors.description && (
              <span className={styles.error}>{errors.description}</span>
            )}
          </label>
          <label>
            Experience Level:
            <select
              name="experienceLevel"
              value={jobData.experienceLevel}
              onChange={handleChange}
            >
              <option value="">Select Experience Level</option>
              <option value="senior">Senior</option>
              <option value="intermediate">Intermediate</option>
              <option value="junior">Junior</option>
            </select>
            {errors.experienceLevel && (
              <span className={styles.error}>{errors.experienceLevel}</span>
            )}
          </label>
          <label>
            Years of Experience:
            <input
              type="text"
              name="yearsOfExperience"
              value={jobData.yearsOfExperience}
              onChange={handleChange}
            />
            {errors.yearsOfExperience && (
              <span className={styles.error}>{errors.yearsOfExperience}</span>
            )}
          </label>
          <label>
            Personnel Required:
            <input
              type="number"
              name="numberOfVacancy"
              value={jobData.numberOfVacancy}
              onChange={handleChange}
              disabled={loading}
            />
            {errors.numberOfVacancy && (
              <span className={styles.error}>{errors.numberOfVacancy}</span>
            )}
          </label>
          <label>
            Education Background:
            <input
              type="text"
              name="educationalBackground"
              value={jobData.educationalBackground}
              onChange={handleChange}
              disabled={loading}
            />
            {errors.educationalBackground && (
              <span className={styles.error}>
                {errors.educationalBackground}
              </span>
            )}
          </label>
          <label>
            Educational Status
            <select
              name="educationalQualification"
              value={jobData.educationalQualification}
              onChange={handleChange}
              disabled={loading}
            >
              <option value="">Select Status</option>
              <option value="BSC">Bsc</option>
              <option value="MSC">Msc</option>
              <option value="PHD">Phd</option>
              <option value="ANY">Other</option>
            </select>
            {errors.educationalQualification && (
              <span className={styles.error}>
                {errors.educationalQualification}
              </span>
            )}
          </label>
          <label>
            GPA:
            <input
              type="number"
              name="gpa"
              value={jobData.gpa}
              onChange={handleChange}
              disabled={loading}
            />
            {errors.gpa && <span className={styles.error}>{errors.gpa}</span>}
          </label>

          <label>
            Gender
            <select
              name="gender"
              value={jobData.gender}
              onChange={handleChange}
              disabled={loading}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="any">Both</option>
            </select>
            {errors.gender && (
              <span className={styles.error}>{errors.gender}</span>
            )}
          </label>
          <label>
            Salary:
            <input
              type="text"
              name="salary"
              value={jobData.salary}
              onChange={handleChange}
              disabled={loading}
            />
            {errors.salary && (
              <span className={styles.error}>{errors.salary}</span>
            )}
          </label>
          <label>
            Job Type:
            <select
              name="jobType"
              value={jobData.jobType}
              onChange={handleChange}
              disabled={loading}
            >
              <option value="">Select Job Type</option>
              <option value="full_time">Full Time</option>
              <option value="part_time">Part Time</option>
              <option value="contractual">Contract</option>
              <option value="internship">Internship</option>
            </select>
            {errors.jobType && (
              <span className={styles.error}>{errors.jobType}</span>
            )}
          </label>
          <label>
            Expiry Date:
            <DatePicker
              className={styles.date}
              selected={jobData.expiryDate}
              onChange={(date) => handleDateChange(date)}
              disabled={loading}
            />
            {errors.expiryDate && (
              <span className={styles.errorex}>{errors.expiryDate}</span>
            )}
          </label>
          <div className={styles.buttons}>
            <button type="submit" className={styles.save} disabled={loading}>
              {loading ? "Creating..." : "Create"}
            </button>
            <button
              type="button"
              className={styles.cancel}
              onClick={onCancel}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      {showSuccessAlert && (
        <div className={styles.successAlert}>Created Successfully!</div>
      )}
    </div>
  );
};

export default CreateJobForm;
