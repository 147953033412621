// import React from 'react'

import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./Calendar.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";

const Calendar = () => {
  const [interviewDates, setInterviewDates] = useState([]);
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const token = sessionStorage.getItem("token"); // Assuming token is stored in localStorage
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const fetchInterviewDates = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/application/scheduled?page=1&limit=10`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setInterviewDates(response.data.applications);
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          navigate("/login"); // Redirect to the login page for unauthorized or forbidden errors
        } else {
          console.error("Error fetching data:", error);
          throw error;
        }
      }
    };

    fetchInterviewDates();
  }, []); // Fetch interview dates whenever token changes

  const formatEvents = () => {
    return interviewDates.map((application) => ({
      id: application._id,
      title: application.fullName, // Or any other suitable title
      start: application.interviewDate,
      // You can set end time if needed
    }));
  };
  const handleEventClick = (clickInfo) => {
    const clickedDate = clickInfo.event.start;
    const applicantsForDate = interviewDates.filter(
      (application) =>
        new Date(application.interviewDate).getTime() === clickedDate.getTime()
    );
    setSelectedApplicants(applicantsForDate);
    setShowModal(true);
  };
  return (
    <div className="calendar-container">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        events={formatEvents()}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        allDaySlot={false}
        slotDuration={"01:00:00"}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        nowIndicator={true}
        // initialEvents={currentEvents}
        // eventsSet={handleEvents}
        // select={handleDateSelect}
        eventClick={handleEventClick}
      />
      {showModal && (
        <Modal
          applicants={selectedApplicants}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Calendar;

const Modal = ({ applicants, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="close-btn" onClick={onClose}>
          <IoMdClose />
        </button>
        <h2>Applicants Scheduled for Interview</h2>
        <ul>
          {applicants.map((applicant) => (
            <li key={applicant._id}>
              <h3>{applicant.fullName}</h3>
              <p>Job Title: {applicant.vacancy.title}</p>
              <p>Email: {applicant.email}</p>
              <p>Phone: {applicant.phone}</p>
              <p>Interview Time: {applicant.interviewTime} Local Time</p>
              {/* Add more applicant information here as needed */}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
