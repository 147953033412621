import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Layout from "./components/Layout/Layout";
// import BoardPage from "./pages/Board/Board";
import Calendar from "./pages/Calendar/Calendar";
import Dashboard from "./pages/Dashboard/Dashboard";
import Jobs from "./pages/Jobs/Jobs";
import Applicants from "./pages/Applicants/Applicants";
import Login from "./pages/Login/Login";
import React, { useState, useEffect, createContext } from "react";
import NotFoundPage from "./pages/NotFound/NotFoundPage";
import { AuthContext } from "./AuthProvider";

const App = () => {
  const token = sessionStorage.getItem("token");

  return (
    <>
      <div id="dashboard">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="*" element={<NotFoundPage />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="calendar" element={<Calendar />} />
              {/* <Route path="board" element={<BoardPage />} /> */}
              <Route path="applicants" element={<Applicants />} />
              <Route path="jobs" element={<Jobs />} />
            </Route>
            <Route path="login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
};

export default App;
