import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import React, { useEffect, useState } from "react";
import axios from "axios";
const StatisticsChart = () => {
  const [chartData, setChartData] = useState([]);
const baseURL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const token = sessionStorage.getItem("token");

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/cronny/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.report;
       
        setChartData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (token) {
      fetchData();
    }
  }, []);
  const option = {
    color: ["var(--orange)"],

    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },

    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      backgroundColor: "rgba(0, 0, 0, 0.59)",
      borderWidth: 0,
    },
    grid: {
      left: "1%",
      right: "1%",
      bottom: "1%",
      containLabel: true,
      show: false,
    },

    xAxis: [
      {
        type: "category",
        axisLabel: {
          fontSize: 14, // Adjust the font size as needed
        },
        boundaryGap: false,
        data: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          fontSize: 14, // Adjust the font size as needed
        },
        axisTick: {
          interval: 10, // Set the interval to the desired value (e.g., 5)
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: [
      {
        name: "Hired",
        type: "line",
        smooth: true,
        lineStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgb(255, 191, 0)",
            },
            {
              offset: 1,
              color: "#F450D3",
            },
          ]),
          width: 4,
        },
        areaStyle: {
          opacity: 0.5,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 0.8, [
            {
              offset: 0,
              color: "#FE4C00",
            },
            {
              offset: 1,
              color: "rgba(255,144,70,0.1)",
            },
          ]),
        },

        emphasis: {
          focus: "series",
        },
        showSymbol: false,
        data: chartData.map((monthData) => monthData.hired),
      },
      {
        name: "Applications",
        type: "line",
        smooth: true,
        lineStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgb(255, 191, 0)",
            },
            {
              offset: 1,
              color: "#F450D3",
            },
          ]),
          width: 4,
        },
        areaStyle: {
          opacity: 0.5,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 0.8, [
            {
              offset: 0,
              color: "#FE4C00",
            },
            {
              offset: 1,
              color: "rgba(255,144,70,0.1)",
            },
          ]),
        },

        emphasis: {
          focus: "series",
        },
        showSymbol: false,
        data: chartData.map((monthData) => monthData.application),
      },
      {
        name: "Job Posted",
        type: "line",
        smooth: true,
        lineStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgb(255, 191, 0)",
            },
            {
              offset: 1,
              color: "#F450D3",
            },
          ]),
          width: 4,
        },
        areaStyle: {
          opacity: 0.5,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 0.8, [
            {
              offset: 0,
              color: "#FE4C00",
            },
            {
              offset: 1,
              color: "rgba(255,144,70,0.1)",
            },
          ]),
        },
        emphasis: {
          focus: "series",
        },
        showSymbol: true,
        data: chartData.map((monthData) => monthData.post),
      },
    ],
  };

  return <ReactECharts option={option} />;
};

export default StatisticsChart;
